

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/pension-alimentaire-calcul-divorce-separation-sercices-avocat.jpeg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const BlogPost39 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Qu’est-ce que la pension alimentaire pour enfants? Services d’avocats en droit de la famille! - Soumissions Avocat"
        description="La séparation d’un couple ayant des enfants rime depuis toujours avec les mots « pension alimentaire ». Véritable secret de polichinelle de la séparation, l’un des conjoints devra verser une pension alimentaire pour subvenir aux besoins de ses enfants. Il sera parfois même tenu d’en verser un à son ex-conjoint, mais celle LIRE PLUS"
        image={LeadImage}>
        <h1>Qu’est-ce que la pension alimentaire pour enfants? Services d’avocats en droit de la famille!</h1>

					
					
						<p>La séparation d’un couple ayant des enfants rime depuis toujours avec les mots « pension alimentaire ». Véritable secret de polichinelle de la séparation, l’un des conjoints devra verser une pension alimentaire pour subvenir aux besoins de ses enfants. Il sera parfois même tenu d’en verser un à son ex-conjoint, mais celle réservée à sa progéniture est suffisamment intéressante pour être l’objet exclusif de ce texte.</p>
<p><StaticImage alt="pension-alimentaire-calcul-divorce-separation-sercices-avocat" src="../images/pension-alimentaire-calcul-divorce-separation-sercices-avocat.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>En tant que parent récemment séparé et bientôt mêlé au processus judiciaire familial, il y a deux ou trois choses que vous devriez savoir à propos de la pension alimentaire pour enfants, dont le montant que vous pourriez être tenu de verser. Voici comment un avocat peut vous aider à payer (ou vous faire payer) le juste prix!</p>
<h2>Quelles sont les obligations des parents envers leurs enfants?</h2>
<p>Avant de discuter des détails spécifiques relatifs à la pension alimentaire, discutons donc brièvement des obligations générales entre les parents et leurs enfants. À ce titre, qu’est-ce qui fait en sorte que les parents sont contraints de payer cette pension alimentaire à leur progéniture? Outre la responsabilité parentale et le devoir moral de base de pourvoir aux besoins des êtres que l’on a nous-mêmes mis au monde, la loi prévoit explicitement que les enfants et les parents se doivent des aliments.</p>
<p>Il s’agit donc d’une relation à deux sens dans laquelle autant le parent que l’enfant se doivent mutuellement assistance en proportion de leurs moyens. De plus, cette obligation ne disparait pas à la majorité (18 ans) de l’enfant; elle subsiste tant que ce dernier n’est pas autonome financièrement, du moment qu’il démontre une volonté et des efforts pour un jour voler de ses propres ailes.</p>
<p>Comment se manifeste l’obligation de l’enfant envers son parent? Par exemple, un enfant qui réussit bien financièrement et qui, une fois rendu à un certain âge, néglige de subvenir aux besoins de ses parents âgés, malades et en difficultés financières, pourrait être contraint de leur verser des « aliments ». Ainsi, cette obligation dure toute une vie.</p>
<p>Cette disposition explique donc la raison d’être de la pension alimentaire à verser et la logique derrière elle, soit le fait qu’elle permet d’exécuter monétairement ce qui ne saurait être fait en nature. Comme l’enfant est en garde partagée ou exclusive, l’un des deux parents n’est par en mesure de contribuer autant que l’autre au quotidien, alors la loi le fait compenser par le versement d’un montant mensuel.</p>
<p>Maintenant que vous comprenez la raison d’être de la pension alimentaire, comment ferez-vous pour connaître le juste prix à payer? En en apprenant davantage sur le <strong>Modèle québécois de fixation des pensions alimentaires pour enfants</strong>! Il s’agit des barèmes qui établissent le juste montant à verser et la façon dont les avocats les font appliquer!</p>
<h2>Le Modèle québécois de fixation des pensions alimentaires pour enfants</h2>
<p>Dans le but d’éviter des poursuites sans fin, des débats judiciaires inutiles et des débats impertinents, le gouvernement du Québec a décidé, à la fin des années 1990, de mettre en place des balises et des barèmes obligatoires visant à fixer la pension alimentaire à verser. C’est ce qu’on appelle le <strong>Modèle québécois des pensions alimentaires pour enfant</strong>.</p>
<p>Ce modèle prévoit tous les scénarios possibles en matière de garde d’enfant (exclusive ou partagée), de revenus parentaux, de droits d’accès, etc. Ainsi, le ce modèle permet au tribunal de tout de suite identifier la situation familiale devant lui pour lui et la coller au barème qui correspond. Il s’agit d’un processus en trois grandes étapes qui vise à établir la pension :</p>
<p><strong>Établir les revenus disponibles séparément : </strong>Le revenu disponible correspond au revenu annuel moins la déduction de base prévue dans la table de fixation.</p>
<p><strong>Établir les revenus disponibles conjointement.</strong> la contribution parentale de base correspond au montant des revenus disponibles des deux parents, qui lui correspond au montant de la table de fixation<strong>.</strong></p>
<p><strong>Déterminer la contribution parentale de chacun :</strong> Pour déterminer le montant de cette contribution, il faut trouver le montant additionné des revenus disponibles parentaux dans la table de fixation pour obtenir le montant qui correspond.</p>
<p>Par exemple, un barème prévoit l’éventualité <strong>d’une garde exclusive confiée à la mère</strong>. Le tribunal, en se fiant au modèle, analysera les moyens financiers de chacun des parents, les contributions obligatoires de base des deux, les frais reliés à la garde ainsi que les autres dépenses connexes pour fixer le montant que le père doit par mois à la mère. Vous remarquerez donc que le tribunal considère le type et le temps de garde qui est confié à chacun des parents comme guide pour fixer le montant à verser.</p>
<p><strong>À quel moment devriez-vous entamer le processus relatif à la pension alimentaire?</strong> Dans les plus brefs délais suivant la séparation! Des délais judiciaires s’imposent toujours dans de tels recours, alors il ne faut pas attendre. Lors d’une demande de divorce, les documents relatifs à la pension alimentaire doivent obligatoirement être joints à la demande.</p>
<p><strong>Peut-on déroger au modèle de pension alimentaire? </strong>Oui, mais cette modification doit être faite par les deux parents et d’un commun accord, en plus d’être approuvée par le juge. Ce dernier vérifiera la demande de modification soumise pour vérifier si elle permet toujours de pallier aux besoins de l’enfant. Les parents devront également justifier leur demande de dérogation afin que le juge puisse en tenir compte lors d’une prochaine demande de révision, le cas échéant.</p>
<p>Il est également possible pour un parent de diminuer ses obligations financières prévues par le modèle de pension alimentaire en alléguant que le montant fixé lui cause des <strong>difficultés excessives</strong>. Le tribunal n’est donc pas lié par ce modèle et peut réviser les barèmes lorsque ceux-ci auraient pour effet de créer une injustice financière pour l’un des parents.</p>
<h2>Divorce et pension alimentaire, les exceptions qui s’appliquent!</h2>
<p>Qu’un couple soit marié, en union civile, de fait ou que l’enfant ne résulte d’aucune relation de couple, le versement et le mode de fixation de la pension alimentaire ne changera pas. Cependant, une seule exception est applicable et elle se trouve au niveau du divorce.</p>
<p><StaticImage alt="divorce-pension-alimentaire-depenses-essentielles-contribution-base" src="../images/divorce-pension-alimentaire-depenses-essentielles-contribution-base.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Lorsque des époux divorcent et qu’au moment de dissoudre l’union, l’un d’eux vit hors du Québec, ce n’est pas le Modèle québécois de fixation des pensions alimentaires pour enfant qui s’appliquera, mais plutôt la loi fédérale sur les pensions alimentaires.</p>
<h2>Les dépenses et documents visés par la pension alimentaire</h2>
<p>La pension alimentaire vise à acquitter les dépenses qui comblent les besoins essentiels des enfants. Elle ne vise pas les frais que l’on qualifie de spéciaux qui eux sont plutôt séparés en proportion des revenus des parents. Voici les différences entre ces deux types de dépenses :</p>
<p><strong>Besoins essentiels :</strong></p>
<ul>
<li>Nourriture</li>
<li>Habitation</li>
<li>Transport pour les loisirs/besoins de l’enfant</li>
<li>Inscription scolaire</li>
<li>Fournitures scolaires</li>
<li>Frais de base dentiste. </li>
</ul>
<p><strong>Dépenses spéciales :</strong></p>
<ul>
<li>Frais de garde des enfants pour vaquer à des obligations professionnelles ou scolaires</li>
<li>Le coût des études supérieures</li>
<li>Les frais d’habitation et d’entretien reliés aux études supérieures </li>
<li>Les dépenses acquittant les besoins particuliers de l’enfant</li>
</ul>
<p>Contrairement aux dépenses essentielles qui sont acquittées par le calcul de la pension alimentaire, les frais spéciaux sont répartis en proportion des revenus disponibles que les parents ont rapportés lors des procédures de pension alimentaire.</p>
<h2>Le montant de la pension alimentaire est-il modifiable?</h2>
<p>En théorie, la loi prévoit que la contribution parentale de base est présumée suffisante pour subvenir à tous les besoins essentiels des enfants. Cette présomption est simple et peut être renversée en prouvant l’insuffisance de celle-ci. À l’inverse, un parent pourrait se dégager de sa responsabilité de la payer en totalité en prouvant qu’il n’a réellement pas les moyens que l’obligation légale lui causerait un préjudice.</p>
<p>De plus, lorsqu’une personne paie déjà plusieurs pensions alimentaires à divers enfants, il se peut que le modèle de fixation de pension lui rende la vie difficile financièrement. Si certains disent avec raison qu’il ne s’agit que d’un cas de justice naturelle, la loi prévoit tout de même que ce pauvre individu peut s’adresser à la cour pour faire diminuer ses obligations. Voici quelques exemples qui peuvent justifier une dérogation au modèle légal :</p>
<ul>
<li>Difficultés excessives à exercer les droits de visite et d’accès pour le parent non gardien.</li>
<li>Un parent est dans une situation de surendettement ou financièrement instable.</li>
<li>Le parent non gardien n’assume pas ses responsabilités de garde partagée ou de droits d’accès, causant un fardeau supplémentaire à l’autre conjoint.</li>
</ul>
<p>Une fois mis en place, il faut avoir une bonne raison pour demander la modification du montant de pension alimentaire à verser. Bien que le tribunal confère un caractère contraignant au Modèle de fixation des pensions alimentaires, il possède néanmoins un important pouvoir discrétionnaire. Il faudra que vous prouviez un changement notable de circonstances pour qu’un juge accepte d’entendre votre cause.</p>
<h2>À quel moment la pension alimentaire prend-elle fin?</h2>
<p>Le versement de la pension alimentaire ne s’arrête pas à l’âge de 18 ans. Il s’agit d’une obligation qui subsiste jusqu’à ce que l’enfant visé soit indépendant financièrement et en mesure de subvenir, seul, à ses propres besoins. Le montant peut être révisé par une demande présentée à la Cour lorsque des changements importants surviennent dans la vie des parents et enfants visés.</p>
<p><StaticImage alt="paiement-pension-alimentaire-enfant-majeur-lois-fonctionnement-avocat" src="../images/paiement-pension-alimentaire-enfant-majeur-lois-fonctionnement-avocat.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Par exemple, une fois l’enfant rendu à l’âge de 18 ans, le parent débiteur de la pension alimentaire peut s’adresser au tribunal pour faire modifier le montant en alléguant un changement de circonstances dans la vie de son enfant.</p>
<p>Si ce dernier travaille à temps partiel ou n’est plus aux études, le montant sera probablement révisé à la baisse, au même titre qu’il pourrait rester exactement le même si cet enfant poursuit des études supérieures dans une ville éloignée. Ce sera au juge de trancher!</p>
<h2>Qu’est-ce qu’un avocat peut faire dans une demande de pension alimentaire?</h2>
<p>Tel que répété à diverses occasions, il est possible de déroger au Modèle de fixation des pensions lorsque les circonstances s’y prêtent. De ce fait, un avocat spécialisé en droit de la famille qui a l’habitude de plaider devant la cour au sujet des pensions alimentaires peut :</p>
<ul>
<li><strong>Demander une révision/modification de la pension établie</strong></li>
<li><strong>Présenter une demande de dérogation aux montants du Modèle de fixation</strong></li>
<li><strong>Vous représenter dans une demande conjointe de divorce </strong></li>
<li><strong>Vous représenter dans une demande conjointe de garde partagée</strong></li>
<li><strong>Analyser la justesse de la pension que vous versez présentement</strong></li>
</ul>
<h2>Soumissions Avocat peut vous référer aux meilleurs avocats en droit de la famille!</h2>
<p>Vous croyez que vous ne recevez pas assez de la part d’un(e) ex-conjoint(e) et que vous avez droit à plus? À l’inverse, vous trouvez que le montant que vous versez est exagéré? N’attendez pas une seconde de plus pour contacter un avocat afin de présenter une demande de modification de pension alimentaire. C’est la qualité de vie de vous et de vos enfants qui en dépend!</p>
<p><strong>Soumissions Avocat est en mesure de vous référer aux meilleurs avocats de la province dans votre région afin de faire respecter votre droit à une pension alimentaire juste!</strong></p>
<p><strong>Il ne vous reste qu’une chose à faire, soit remplir le formulaire au bas de la page afin de recevoir une offre de la part d’un avocat. Nos services sont gratuits, alors contactez-nous!</strong></p>
    </SeoPage>
)
export default BlogPost39
  